import axios from 'axios';
import Cookie from 'universal-cookie';

export async function getTokenOrRefresh() {
    const cookie = new Cookie();
    const speechToken = cookie.get('speech-token');
    const authToken = localStorage.getItem('token'); // Retrieve the JWT token from localStorage

    if (speechToken === undefined && authToken) {
        try {
            // Include the JWT token in the request header
            const res = await axios.get('/api/get-speech-token', {
                headers: { Authorization: `Bearer ${authToken}` }
            });
            const token = res.data.token;
            const region = res.data.region;
            cookie.set('speech-token', region + ':' + token, {maxAge: 7200, path: '/'});

            console.log('Token fetched from back-end: ' + token);
            return { authToken: token, region: region };
        } catch (err) {
            console.log(err.response ? err.response.data : err);
            return { authToken: null, error: err.response ? err.response.data : 'Error fetching token' };
        }
    } else if (speechToken) {
        console.log('Token fetched from cookie: ' + speechToken);
        const idx = speechToken.indexOf(':');
        return { authToken: speechToken.slice(idx + 1), region: speechToken.slice(0, idx) };
    } else {
        return { authToken: null, error: 'No token available' };
    }
}
