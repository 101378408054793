import React, { useContext, useRef, useState } from 'react';

import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Layout from "../../vmesnik-komponente/Layouts/LayoutLogin";
import { AuthContext } from '../../context/AuthContext';


export default function Login() {
    const { setIsAuth } = useContext(AuthContext); // Use setIsAuth from AuthContext
    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const email = emailRef.current.value;
        const password = passwordRef.current.value;

        try {
            const response = await axios.post('/api/login', { email, password });
            localStorage.setItem('token', response.data.token);
            setIsAuth(true); // Update auth state on successful login
            navigate('/Prevajalnik'); // Navigate to the protected route
        } catch (error) {
            console.error('Failed to login', error);
            setErrorMessage('Nepravilno uporabniško ime ali geslo'); // Display an error message
        }
    };

    return (
        <>
            <Layout>
                <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 items-center">
                    <div className="bg-gray-900 justify-center rounded-xl p-12">
                        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                            <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-white">
                                VPISNI PORTAL <span className='text-[#4AAFB8]'>AIBCROBOTICS</span>
                            </h2>
                        </div>

                        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                            <form className="space-y-6" onSubmit={handleSubmit}>
                                <div>
                                    <label className="block text-base font-semibold leading-6 text-white">
                                        Uporabniško ime
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            ref={emailRef}
                                            id="email"
                                            name="email"
                                            
                                            autoComplete="email"
                                            required
                                            className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>

                                <div>
                                    <div className="flex items-center justify-between">
                                        <label htmlFor="password" className="block text-base font-semibold leading-6 text-white">
                                            Geslo
                                        </label>
                                    </div>
                                    <div className="mt-2">
                                        <input
                                            ref={passwordRef}
                                            id="password"
                                            name="password"
                                            type="password"
                                            autoComplete="current-password"
                                            required
                                            className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>

                                {errorMessage && (
                                    <div className="text-red-500">{errorMessage}</div>
                                )}

                                <div>
                                    <button
                                        type="submit"
                                        className="flex w-full justify-center rounded-md bg-[#4AAFB8] px-3 py-1.5 text-base font-semibold leading-6 text-white shadow-sm hover:bg-[#04a2b0] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                    >
                                        Vpis
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
}
