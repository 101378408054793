import { useNavigate } from 'react-router-dom';


export default function NotFound() {

    const navigate = useNavigate();
    
    const handleGoHome = () => {
        navigate('/login'); // Redirect to the login page
      };


    return (
      <>

        <main className="h-[100vh] grid min-h-full place-items-center bg-gray-800 px-6 py-24 sm:py-32 lg:px-8">
          <div className="text-center">
            <p className="text-5xl font-semibold text-[#4AAFB8]">404</p>
            <h1 className="mt-4 text-3xl font-bold tracking-tight text-white sm:text-5xl">Stran ne obstaja</h1>
            <p className="mt-6 text-xl leading-7 text-white">Žal ne najdemo strani, ki jo iščete</p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
                    <button
                    onClick={handleGoHome}
                    className="rounded-md bg-[#4AAFB8] px-3.5 py-2.5 text-base font-semibold text-white shadow-sm hover:bg-[#04a2b0] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                    Pojdi domov
                    </button>
            </div>
          </div>
        </main>
      </>
    )
  }
  