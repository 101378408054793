import React, { useState } from 'react';

import Select from 'react-select';

const colourOptions = [
  { value: {from: "en-US", to: "sl-SI"}, label: 'Angleško - Slovensko' },
  { value: {from: "sl-SI", to: "en-US"}, label: 'Slovensko - Angleško' },
  { value: {from: "hu-HU", to: "sl-SI"}, label: 'Madžarsko - Slovensko' },
];

export default ({ onChangeSelect, isDisabled }) => {

  return (
    <>

      <Select
        className="basic-single"
        classNamePrefix="select"
        defaultValue={colourOptions[0]}
        name="color"
        options={colourOptions}
        menuPlacement="top"  // This will make the dropdown open upwards
        onChange={onChangeSelect}
        isDisabled={isDisabled}

      />

      <div
        style={{
          color: 'hsl(0, 0%, 40%)',
          display: 'inline-block',
          fontSize: 12,
          fontStyle: 'italic',
  
        }}
      >
       
      </div>
    </>
  );
};
