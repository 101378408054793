import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuth, setIsAuth] = useState(false);

    const isAuthenticated = async () => {
        const token = localStorage.getItem('token');
        if (!token) return false;

        try {
            const response = await axios.get('/api/validate-token', {
                headers: { Authorization: `Bearer ${token}` }
            });
            return response.status === 200;
        } catch (error) {
            console.error('Token validation error:', error);
            return false;
        }
    };

    useEffect(() => {
        const checkAuth = async () => {
            const auth = await isAuthenticated();
            setIsAuth(auth);
            console.log(auth)
        };
        checkAuth();
    }, []);

    return (
        <AuthContext.Provider value={{ isAuth, setIsAuth }}>
            {children}
        </AuthContext.Provider>
    );
};
