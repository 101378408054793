import React from 'react';

// ButtonUniversal component
export default function ButtonUniversal({ onClick, Icon, text, disabled, additionalClasses }) {
    return (
        <div className='flex justify-center '>
          
            <button
                disabled={disabled}
                type="button"
                className={`justify-start sm:justify-center hover:text-white lg:text-xl w-full inline-flex items-center rounded-md border border-transparent bg-[#4AAFB8] px-6 py-1.5 text-base font-medium text-white shadow-sm hover:bg-[#04a2b0] disabled:opacity-25 ${additionalClasses}`}
                onClick={onClick}
            >
                {Icon && <Icon className="-ml-1 mr-2 h-5 w-5 sm:h-6 sm:w-6" aria-hidden="true" />}
                {text && <span className='font-bold text-sm'>{text}</span>}
            </button>
        </div>
    );
}
