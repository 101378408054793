import React, { useState } from 'react';

//import useAuth from '@/hooks/useAuth';

const Layout = ({ children }) => {

  return (
   
    <div className='' style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
    {/*{isLoggedIn ? (*/}
        <>
          <div style={{ flex: '0 0 auto' }}>
            {/*<HeaderTailwind positionLogo='right' toggleButton={true} toggleDrawer={toggleDrawer} />*/}
          </div>
          <div className="bg-gray-800  py-[10px] sm:py-0" style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>

              {children}
           
          </div>

          <div style={{ flex: '0 0 auto' }}>
            
          </div>

        </>
    {/*) : "" }*/}
    </div>  
  )
};

export default Layout;
