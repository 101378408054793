import { useEffect, useRef } from 'react';
import '@fortawesome/fontawesome-free/css/all.css';

export default function TekstPolje({ isFullScreen , recognizedTranslations, currentTranslation, classes, klikni, toggle2, prevodBesedilo }) {

  const messagesEndRef = useRef(null); // Ref for the scrolling to the latest message

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(scrollToBottom, [currentTranslation]);

  let transformedPrevedibesedilo = prevodBesedilo === "sl-SIen-US" ? 'Slovenščino' : 'Angleščino';

  if(prevodBesedilo === "sl-SIen-US")
  {
    transformedPrevedibesedilo = "Angleščino"
  }
  else if(prevodBesedilo === "en-USsl-SI")
  {
    transformedPrevedibesedilo = "Slovenščino"
  }

  return (
    <div
      onClick={() => klikni()}
      className={`${isFullScreen ? 'py-2' : 'py-6'}  px-6 w-full flex justify-center flex-col ${classes}`}
      style={{ position: 'relative', height: isFullScreen ? '100vh' : undefined }}
    >

  <div className={`${isFullScreen ? 'text-[1.5rem] sm:text-[2rem]' : 'text-lg sm:text-3xl'} text-center font-bold z-20 relative flex justify-center sm:justify-center  text-[#4AAFB8] `}>
       <span className='text-white'>PREVAJALNIK&nbsp;</span>   <span className='text-[#4AAFB8]'>AIBCROBOTICS</span>
      </div>

    <div className={`${isFullScreen ? 'text-[1.25rem] sm:text-[1.75rem]' : 'text-lg sm:text-xl'} pb-2 text-center font-semibold z-20 relative flex justify-center sm:justify-center  border-b-2 border-[#4AAFB8] text-white ${!toggle2 && 'animate-pulse'}`}>
    {toggle2 ? "Izberite jezik in pritisnite gumb snemaj" : ( <>Prevajam v&nbsp; <span className='text-[#4AAFB8]'>{transformedPrevedibesedilo}</span></>
      )
}
      </div>

      {/*bg-gray-800 rounded-b-xl*/}
      <div className={`  px-4 overflow-auto py-6   ${isFullScreen ? 'h-full' : 'h-[40vh] md:h-[60vh]'}`}>

          {recognizedTranslations?.map((translation, index) => (
            <div key={index} className={`p-2 sm:p-2 font-semibold text-white translation-row ${isFullScreen ? 'text-[2rem]' : 'text-lg sm:text-xl'}`}>
              <span className="text-[#4AAFB8] pr-1">{index + 1}.</span> {translation}
            </div>
          ))}

          <div className={`  p-2 sm:p-2 font-semibold translation-row text-white  ${isFullScreen ? 'text-[2rem]' : 'text-lg sm:text-xl'}`}>
            {currentTranslation}
          </div>

        {/* Invisible element for scrolling into view */}
        <div ref={messagesEndRef} />

        
      </div>
    </div>
  );
}
