import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './vmesnik-pages/Login/login';
import Prevajalnik from "./vmesnik-pages/Prevajalnik/Prevajalnik";
import NotFound from './vmesnik-pages/404/notFound';
import { AuthProvider } from './context/AuthContext';
import { AuthContext } from './context/AuthContext';

function App() {
    return (
        <AuthProvider> {/* Wrap your routes with AuthProvider */}
            <Router>
                <Routes>
                    <Route exact path="/" element={<Login />} />
                    <Route path="/login" element={<Login />} />
                    <Route
                        path="/Prevajalnik"
                        element={
                            <RequireAuth redirectTo="/login">
                                <Prevajalnik />
                            </RequireAuth>
                        }
                    />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Router>
        </AuthProvider>
    );
}

function RequireAuth({ children, redirectTo }) {
    const { isAuth } = useContext(AuthContext); // Use useContext hook to access AuthContext

    return isAuth ? children : <Navigate to={redirectTo} />;
}

export default App;
