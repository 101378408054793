import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames'
import TekstPolje from "../../vmesnik-komponente/components/textArea"
import '../../index.css';
import Layout from '../../vmesnik-komponente/Layouts/Layout';
import Select from '../../vmesnik-komponente/components/Select';
import { useNavigate } from 'react-router-dom';
import ButtonUniversal from "../../vmesnik-komponente/components/ButtonUniversal"
import {  Play, Stop, XCircle } from 'heroicons-react';
import { Clipboard } from 'heroicons-react';
import { Logout } from 'heroicons-react';
import { Download } from 'heroicons-react';
import { Backspace } from 'heroicons-react';
import { Film } from 'heroicons-react';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import {useSpeechTranslation} from "../../useSpeechTranslation"
import { ArrowCircleLeft } from 'heroicons-react';


export default function Prevajalnik() {

  const [gumbi, setSkrijGumbi] = useState(true);

  function klikni()
  {
    setSkrijGumbi(!gumbi)
  }

  const {
    setFromLanguage,
    setToLanguage,
    fromLanguage,
    toLanguage,
    toggle1,
    toggle2,
    recognizedTranslations, // This replaces your local `recognizedTranslations` state
    currentTranslation, // This replaces your local `currentTranslation` state
    startTranslation, // This replaces your local `startTranslation` function
    stopTranslation, // This replaces your local `stopTranslation` function
    clearText // This replaces your local `clearText` function
  } = useSpeechTranslation();

  function onChangeSelect(selectedOption) {
    setFromLanguage(selectedOption.value.from);
    setToLanguage(selectedOption.value.to);
  }

  const { setIsAuth } = useContext(AuthContext);

  const logout = () => {
      localStorage.removeItem('token');
      setIsAuth(false); // Update the auth state
      // Redirect to login or perform other actions
  };

  const izvoziBesedilo = () => {
    // Create a string with line numbers
    const combinedText = recognizedTranslations.map((translation, index) => 
      `${index + 1}. ${translation}`).join('\n');
    // Create a Blob from the string
    const textBlob = new Blob([combinedText], { type: 'text/plain' });
    // Create a URL for the Blob
    const textURL = window.URL.createObjectURL(textBlob);
    // Create a temporary anchor element and trigger download
    const tempLink = document.createElement('a');
    tempLink.href = textURL;
    tempLink.download = 'transkripcija.txt'; // Name of the file
    document.body.appendChild(tempLink); // Required for Firefox
    tempLink.click();
    document.body.removeChild(tempLink); // Remove the temporary link
    window.URL.revokeObjectURL(textURL); // Clean up the URL
  };
  
  return (

    <div className="flex flex-col justify-center items-center h-screen bg-gray-800">

          <div className={` bg-gray-900 rounded-xl ${gumbi ? 'lg:w-[70%] w-[96%]' : 'w-full h-full flex justify-center '}`}>
            <TekstPolje
            
              prevodBesedilo={fromLanguage + toLanguage}
              toggle2={toggle2}
              recognizedTranslations={recognizedTranslations}
              currentTranslation={currentTranslation}
              isFullScreen={!gumbi} 
              id="tekst1"
              klikni={klikni} 
              uredi={true}
              classes={classNames("transition-all duration-300 flex-grow sm:flex-grow-0 ",  {
                //"bg-gray-900": gumbi,
                //"bg-gray-800": !gumbi,
                "overflow-auto": !gumbi, // Allow scrolling when in full screen

              })}
            />
         
         <div
          className={classNames(
            "px-4 transition-all py-4 duration-300 flex flex-col sm:space-y-0",
            { 'hidden': !gumbi },
            'justify-center lg:justify-start ' // Use responsive classes
          )}
        >
          <Select isDisabled={toggle1} onChangeSelect={onChangeSelect}></Select>

          <div className='flex sm:flex-row flex-col space-y-2 sm:space-y-0 sm:space-x-4 pt-3'>
            {/* Flex item for each button */}

            <div className='flex-grow'>
            <ButtonUniversal
              onClick={toggle2 ? startTranslation : stopTranslation}
              Icon={toggle2 ? Play : Stop}
              text={toggle2 ? "Snemaj" : "Ustavi"}
              zakljuci={toggle2 ? toggle1 : toggle2}
            />
            </div>
            
            <div className='flex-grow'>
              <ButtonUniversal onClick={clearText} Icon={Backspace} text="Pobrisi vsebino" />
            </div>

            <div className='flex-grow'>
              <ButtonUniversal onClick={izvoziBesedilo} Icon={Download} text="Izvozi besedilo" />
            </div>

            <div className='flex-grow'>
              <ButtonUniversal className="text-white" onClick={logout} Icon={ArrowCircleLeft} text="Izpis" />
            </div>
          </div>
        </div>
        
    </div>
    </div>

  );
}
