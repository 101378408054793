import { useState, useRef, useEffect } from 'react';
import { getTokenOrRefresh } from './token_util';
const speechsdk = require('microsoft-cognitiveservices-speech-sdk');

export const useSpeechTranslation = () => {
  
  const [fromLanguage, setFromLanguage] = useState('en-US');
  const [toLanguage, setToLanguage] = useState('sl-SI');
  const [isTranslationActive, setIsTranslationActive] = useState(false);
  const [recognizedTranslations, setRecognizedTranslations] = useState([]);
  const [currentTranslation, setCurrentTranslation] = useState('');
  const [translationText, setTranslationText] = useState('Ready to translate speech...');
  const translationRecognizerRef = useRef(null);

  const [toggle1, setToggle1] = useState(false);
  const [toggle2, setToggle2] = useState(true);

  // Start translation
  const startTranslation = async () => {

    // Update toggle states when starting translation
    setToggle1(true); // Indicates translation has started
    setToggle2(false); // Indicates translation can be stopped

    const tokenObj = await getTokenOrRefresh();
    const translationConfig = speechsdk.SpeechTranslationConfig.fromAuthorizationToken(
      tokenObj.authToken,
      tokenObj.region
    );
    translationConfig.speechRecognitionLanguage = fromLanguage;
    translationConfig.addTargetLanguage(toLanguage);

    const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();
    translationRecognizerRef.current = new speechsdk.TranslationRecognizer(
      translationConfig,
      audioConfig
    );

    translationConfig.setProfanity(speechsdk.ProfanityOption.Raw);

    translationRecognizerRef.current.recognizing = (s, e) => {
      if (e.result.reason === speechsdk.ResultReason.TranslatingSpeech) {
        const resultJson = JSON.parse(e.result.privJson);
        const translation =
          resultJson.privTranslationHypothesis.Translation.Translations[0].Text;
        // This updates the translating text in place, without pushing it to recognizedTranslations
        setCurrentTranslation(`${translation}`);
      }
    };
    
    translationRecognizerRef.current.recognized = (s, e) => {

      let language = toLanguage === "en-US" ? 'en' : (toLanguage === "sl-SI" ? 'sl' : null);

      console.log('Recognized event received:', e);
      const slovenianTranslation = e.result.translations.get(language);
      setRecognizedTranslations(prevTranslations => [...prevTranslations, `${slovenianTranslation}`]);
      setCurrentTranslation(''); // Clear the translating text once we have a translated one
    };
  
    translationRecognizerRef.current.canceled = (s, e) => {
      setTranslationText(`CANCELED: Reason=${e.reason}`);
    };

    translationRecognizerRef.current.sessionStopped = (s, e) => {
      if (translationRecognizerRef.current) {
        stopTranslation(); // Call the custom stopTranslation function
        setIsTranslationActive(false); // Set the flag to indicate that translation is stopped
      }
    };

    setTranslationText('Starting translation...');
    translationRecognizerRef.current.startContinuousRecognitionAsync();
    setIsTranslationActive(true); // Set the flag to indicate that translation is active
  }

  // Custom function to stop the recognizer
  function stopTranslation() {
    console.log("USTAVI")

    setToggle1(false)
    setToggle2(true)

    if (translationRecognizerRef.current) {
      translationRecognizerRef.current.stopContinuousRecognitionAsync(() => {
        setTranslationText('Translation stopped.');
        setIsTranslationActive(false); // Set the flag to indicate that translation is stopped
      });
    }
  }
  // Clear text
  const clearText = () => {
    setCurrentTranslation("");
    setRecognizedTranslations([]);
    setTranslationText("");
    };

  // Cleanup
  useEffect(() => {
    return () => {
      if (translationRecognizerRef.current) {
        translationRecognizerRef.current.close();
      }
    };
  }, []);

  return {
    toggle1,
    toggle2,
    fromLanguage,
    setFromLanguage,
    toLanguage,
    setToLanguage,
    isTranslationActive,
    recognizedTranslations,
    currentTranslation,
    translationText,
    startTranslation,
    stopTranslation,
    clearText
  };
};
